export enum Stage {
    test = "test",
    alpha = "alpha",
    beta = "beta",
    prod = "prod",
}

export const EMBEDDED_WORKFLOW_UI_COMPONENT_NAME = "EmbeddedWorkflowUI";
export const WORKFLOW_UI_COMPONENT_NAME = "WorkflowUI";

export const DEFAULT_OLD_BASE_REM_VALUE = 10;
